import NavigationBar from './NavigationBar';
import { useAppContext } from '../AppContext';
import { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';


import demoPic1 from '../assets/images/works/connectfour.png';
import demoVid1 from '../assets/videos/portfolio/connectFour.mp4';
import demoPic2 from '../assets/images/works/todolist.png';
import demoVid2 from '../assets/videos/portfolio/todoApp.mp4';
import demoPic3 from '../assets/images/apps/roulette.png';
import demoVid3 from '../assets/videos/portfolio/rouletteApp.mp4';
import demoPic4 from '../assets/images/works/nayeDesigns.JPG';
import demoPic6 from '../assets/images/works/netconn.JPG';
import demoVid4 from '../assets/videos/portfolio/nayeDesigns.mp4';
import demoVid6 from '../assets/videos/portfolio/netconn.mp4';
import demoPic5 from '../assets/images/works/dashboardOSC.JPG';
import demoVid5 from '../assets/videos/portfolio/dashboardOSCFS.mp4';
import iconVisitSite from '../assets/images/icons/arrow-visitSite.png';
import downloadIcon from '../assets/images/icons/download.png';
import './ServicesDemo.css';

function ServicesDemo() {




    const { currentLanguage } = useAppContext();
    const [currentIndex, setCurrentIndex] = useState(0);

    const currentIndexRef = useRef(0);

    const serviceDemosEnglish = [
        {
            content: {
                title: 'nayeDesigns',
                type: 'Ecommerce',
                niche: 'Artisanal',
                body: 'Full stack Ecommerce site. Mobile and Desktop. Built with React, React Native and Node.js',
                indicator: 'website',
            },
            link: 'https://naye-web.onrender.com/',
            image: demoPic4,
            video: demoVid4,

        },
        {
            content: {
                title: 'netconn Engineering',
                type: 'Store/Ecommerce',
                niche: 'ICT',
                body: 'Full stack store/ecommerce site. Desktop. Built with React, React Native and Node.js',
                indicator: 'website',
            },
            link: 'https://netconn-engineering.onrender.com/',
            image: demoPic6,
            video: demoVid6,

        },
        {
            content: {
                title: 'Connect Four',
                type: 'Entertainment',
                niche: 'Game',
                body: 'Full stack web application game. Built with React and Node.js',
                indicator: 'website',
            },
            link: 'https://connect-four-pz-designs.onrender.com/',
            image: demoPic1,
            video: demoVid1,
        },
        {
            content: {
                title: 'Data Analytics dashboard',
                type: 'Dashboard design',
                niche: 'World Bank Indicators',
                body: 'Full stack Dashboard system. Built with React and Node.js',
                indicator: 'website',
            },
            link: 'https://db-osc.onrender.com/',
            image: demoPic5,
            video: demoVid5,
        },
        {
            content: {
                title: 'ToDOList',
                type: 'Web App',
                niche: 'Productivity',
                body: 'User friendly to do list web application. Built with Javascript, HTML, and Sass',
                indicator: 'website',
            },
            link: 'https://brukgit.github.io/toDoListApp/',
            image: demoPic2,
            video: demoVid2,
        },
        {
            content: {
                title: 'Roulette|ሮሌት',
                type: 'Android App',
                niche: 'Entertainment',
                body: 'Roulette game app ' +
                    'developed using React Native and Expo.',
                indicator: 'app',
            },
            link: 'https://expo.dev/@babexpo/rouletteMain?serviceType=classic&distribution=expo-go',
            image: demoPic3,
            video: demoVid3,
        }
    ]
    const serviceDemosAmharic = [
        {
            content: {
                title: 'nayeDesigns',
                type: 'ኢኮሜርስ ሱቅ',
                niche: 'እጅ ሥራና የአገር ውስጥ እቃዎች',
                body: 'ፉል ስታክ የዌብሳይት ኢኮሜርስ ሱቅ ፤ ሞባይልና ዴስክቶፕ፡፡ በ React JS ፡ React Native አና በ Node.js የተገነባ',
                indicator: 'website',
            },
            link: 'https://naye-web.onrender.com/',
            image: demoPic4,
            video: demoVid4,
        },
        {
            content: {
                title: 'netconn Engineering',
                type: 'ሱቅ | ኢኮሜርስ',
                niche: 'አይሲቲ',
                body: 'ፉል ስታክ ሱቅ|ኢኮሜርስ፤ ዴስክቶፕ፡፡  በ React JS አና በ Node.js የተገነባ',
                indicator: 'website',
            },
            link: 'https://netconn-engineering.onrender.com/',
            image: demoPic6,
            video: demoVid6,

        },
        {
            content: {
                title: 'Connect Four',
                type: 'የዌብሳይት መተግበሪያ',
                niche: 'ጨዋታ',
                body: 'ፉል ስታክ የዌብሳይት የጨዋታ መተግበሪያ፤ በ React JS አና በ Node.js የተገነባ',
                indicator: 'website',
            },
            link: 'https://connect-four-pz-designs.onrender.com/',
            image: demoPic1,
            video: demoVid1,
        },
        {
            content: {
                title: 'Data Analytics dashboard',
                type: 'ዳሽቦርድ',
                niche: 'ዎርልድ ባንክ መለኪያዎች',
                body: 'ፉል ስታክ ዳሽቦርድ ሲስተም. በ React JS አና በ Node.js የተገነባ',
                indicator: 'website',
            },
            link: 'https://db-osc.onrender.com/',
            image: demoPic5,
            video: demoVid5,
        },
        {
            content: {
                title: 'ToDOList',
                type: 'የዌብሳይት መተግበሪያ',
                niche: 'ቅልጥፍናና ምርታማነት',
                body: 'ለአጠቃቀም ምቹ የሆነ ሥራ ማቀጃ፤ በ Javascript፤ HTML አና Sass የተገነባ',
                indicator: 'website',
            },
            link: 'https://brukgit.github.io/toDoListApp/',
            image: demoPic2,
            video: demoVid2,
        },
        {
            content: {
                title: 'Roulette|ሮሌት',
                type: 'የAndroid መተግበሪያ',
                niche: 'መዝናኛ',
                body: 'ሮሌት መጫወቻ ፤ በ React Native አና Expo የተገነባ',
                indicator: 'app',
            },
            link: 'https://expo.dev/@babexpo/rouletteMain?serviceType=classic&distribution=expo-go',
            image: demoPic3,
            video: demoVid3,
        }
    ]

    const [refDemo, inView] = useInView();
    const [mouseOver, setMouseOver] = useState(false);
    const header_title = currentLanguage === 'English' ? 'Past Works' : 'ሥራዎቻችን በትንሹ';
    const serviceDemos = currentLanguage === 'English' ? serviceDemosEnglish : serviceDemosAmharic;

    useEffect(() => {
        const intervalId = setInterval(() => {
            inView && (!mouseOver) && setCurrentIndex(currentIndexRef.current);
            currentIndexRef.current = (currentIndexRef.current + 1) % serviceDemos.length;

        }, 4000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [setCurrentIndex, serviceDemos.length, inView, mouseOver]);



    const handleCTAClick = () => {
        console.log("Nothing to do for now.")
    }

    const handleDownload = () => {
        setProgressMessage(true);
        const filePath = process.env.PUBLIC_URL + '/universal.apk';
        const link = document.createElement('a');
        link.href = filePath;
        link.download = 'universal.apk';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [progressMessage, setProgressMessage] = useState(false);

    useEffect(() => {

        // Set a timeout to remove progress message
        const timeoutId = setTimeout(() => {
            setProgressMessage(false);
        }, 3000);

        // Clear the timeout to avoid memory leaks
        return () => clearTimeout(timeoutId);
    }, [progressMessage]);
    const LinkButton = ({ link }) => {
        const linkLabel = currentLanguage === 'English' ? 'Visit Site' : 'ጣቢያውን ይጎብኙ';
        const handleCTAClick = () => {
            if (link) {
                window.open(link, '_blank');
            }
        };

        return (
            <button className="link-to-site-services" onClick={handleCTAClick}>
                {linkLabel} <img src={iconVisitSite} alt="Arrow icon" className='visit-icon' />
            </button>
        );
    };

    const { ref: demosContainerRef, inView: demosInView } = useInView();
    const demosControls = useAnimation();
    const [demosAnimationCompleted, setDemosAnimationCompleted] = useState(false);
    
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.play().catch(error => {
                            console.error("Error attempting to play video:", error);
                        });
                    } else {
                        entry.target.pause();
                    }
                });
            },
            { threshold: 0.1 } // Reduced threshold for earlier playback
        );

        const observeVideos = () => {
            if (refDemo.current) {
                const videos = refDemo.current.querySelectorAll('video');
                videos.forEach((video) => {
                    observer.observe(video);
                    // Attempt to play the video immediately
                    video.play().catch(error => {
                        console.error("Error attempting to play video:", error);
                    });
                });
            }
        };

        // Run once after initial render
        observeVideos();

        // Set up a MutationObserver to watch for changes in the DOM
        const mutationObserver = new MutationObserver(observeVideos);
        if (refDemo.current) {
            mutationObserver.observe(refDemo.current, { childList: true, subtree: true });
        }

        return () => {
            if (refDemo.current) {
                const videos = refDemo.current.querySelectorAll('video');
                videos.forEach((video) => observer.unobserve(video));
            }
            mutationObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        if (demosInView && !demosAnimationCompleted) {

            demosControls.start({

                opacity: 1,
                y: 0,
                x: [0, -10, 10, -10, 10, 0],

                transition: { delay: 0.5 }
            });
            setDemosAnimationCompleted(true);
        } else {
            if (demosInView && demosAnimationCompleted) {
                demosControls.start({ opacity: 1, y: 0, x: 0 });
            } else {
                demosControls.start({ opacity: 0, y: 50, x: 0 });
            }
        }
    }, [demosControls, demosInView]);



    const title = currentLanguage === 'English' ? 'Title' : 'ርዕስ';
    const type = currentLanguage === 'English' ? 'Type' : 'ዓይነት';
    const niche = currentLanguage === 'English' ? 'Niche' : 'መደብ';
    const download_msg = currentLanguage === 'English' ? 'Download APK for Android' : 'ለAndroid ስልክዎ ይጫኑ';
    const download_progress = currentLanguage === 'English' ? 'Your download starts shortly.።' : 'ማውረድ ይጀምራል..';

    return (
        <motion.div className="demo-container" ref={demosContainerRef}
            initial={{ opacity: 0, y: 100 }}
            animate={demosControls}
            custom={1}>

            <div>
                <h2 className='sub-header'>{header_title}</h2>
                <div className='demos' ref={refDemo}
                    style={{ position: 'relative' }}>
                    {serviceDemos.map((item, index) => (
                        <div key={index}
                            style={{ order: `${index}` }}

                            className={`demo-item-services ${index === currentIndex ? 'highlight' : ''}`}
                            onMouseEnter={() => {
                                setCurrentIndex(index)
                                setMouseOver(true)
                            }}
                            onMouseLeave={() => setMouseOver(false)}>
                            {/* <img src={item.image} className='demoPic-services' alt='Website/app snapshot designed by the company, brosfe.'
                                /> */}
                             <video
                            src={item.video}
                            className='demoPic-services'
                            alt='Website/app snapshot designed by the company, brosfe.'
                            loop
                            muted
                            playsInline
                            autoPlay
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                position: 'absolute',
                                top: '0',
                                left: '0'
                            }}
                        />
                            <div>
                                <p>{title}-<span style={{ fontWeight: 'bold' }}>{item.content.title}</span></p>
                                <p>{type}- <span style={{ fontWeight: 'bold' }}>{item.content.type}</span></p>
                                <p>{niche}- <span style={{ fontWeight: 'bold' }}>{item.content.niche}</span></p>
                                <p>{item.content.body}</p>
                            </div>
                            <LinkButton link={item.link} />
                            {item.content.indicator === 'app' && (
                                <button className='link-to-site-services' onClick={handleDownload}>
                                    <img src={downloadIcon} alt="download icon" className='visit-icon' /> {download_msg}
                                </button>
                            )}


                            <div style={{
                                position: 'absolute', bottom: '-25px', right: '-25px',
                                width: '50px', height: '50px', borderRadius: '50%'
                            }}>

                            </div>

                        </div>
                    ))}
                    {progressMessage && (

                        <span className='download-message'>{download_progress}</span>

                    )}



                </div>



            </div>





        </motion.div>

    );
}

export default ServicesDemo;
